// g-captcha
function onSubmitRecaptcha() {
    $('input[type=submit]').removeAttr('disabled');
}

$(document).ready(function() {

    // alertModal
    if ($('#alertModal').length) {
        $('#alertModal').modal('show');
    }


    // validate forms
    $.validate({
        validateOnBlur : false, // disable validation when input looses focus
        errorMessagePosition : 'top', // Instead of 'element' which is default
        scrollToTopOnError : false // Set this property to true if you have a long form
    });


    // owl carousel
    $(".owl-carousel").owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:false,
        items: 1
        //afterAction: function(current) {
        //    current.find('video').get(0).play();
        //}
    });


    // collapse project filters when pressed
    $(document).on('click', 'ul.project-filters li a', function() {
       $('#collapseFilters').collapse('hide');
    });


    // auto hide navbar on scrolling
    $(".navbar-fixed-top").autoHidingNavbar();


    // style navbar to be transparent on homepage
    if(paths.base === paths.current) {
        $(".navbar-inverse").attr('style', 'background-color:transparent !important');

        $(window).scroll(function(){
            if($(window).scrollTop() < ($(window).height() - 100)){
                $(".navbar-inverse").attr('style', 'background-color:transparent !important');
            } else {
                $(".navbar-inverse").attr('style', '');
            }
        });
    }


    // menu
    $(document).on('click touchend', '.nav-menu-button.closed', function() {
        $('.nav-menu-button i').addClass('fa-rotate-90');
        $('.nav-menu-button span').fadeOut();
        $('.nav-menu').hide().removeClass('hidden').fadeIn();
        $('.nav-menu-button').removeClass('closed').addClass('opened');
    });

    $(document).on('click touchend', '.nav-menu-button.opened', function() {
        $('.nav-menu-button i').removeClass('fa-rotate-90');
        $('.nav-menu').fadeOut();
        $('.nav-menu-button span').fadeIn();
        $('.nav-menu-button').removeClass('opened').addClass('closed');
    });


    // Slider
    var Slider = {
        init: function() {
            this.slides = $('.owl-carousel .slide-image');
            this.videos = $('.owl-carousel .slide-video video');
            this.updateSlides();
            this.updateVideos();
        },
        updateSlides: function() {
            for (var i = 0; i < this.slides.length; i++) {
                $(this.slides[i]).css('background-image', 'url(' + $(this.slides[i]).data('background') + ')');
            }
        },
        updateVideos: function() {
            for (var i = 0; i < this.videos.length; i++) {
                var myVideo = this.videos[i];
                $(myVideo).css('background-image', 'url(' + $(myVideo).data('background') + ')');
                $(myVideo).get(0).play();
            }
        }
    };
    Slider.init();


    /**
     * ProjectImages
     * Enables admins to add and remove images to the back-end
     * @type {{init: Function, bindEvents: Function, createImage: Function, destroyImage: Function}}
     */
    var ProjectImages = {
        init: function() {
            this.project_images = $('#project-images');
            this.btn_create = $('.btn-image-create');
            this.btn_destroy = $('.btn-image-destroy');
            this.bindEvents();
        },
        bindEvents: function() {
            this.btn_create.click($.proxy(this.createImage, this));
            $(document).on('click', '.btn-image-destroy', $.proxy(this.destroyImage, this));
        },
        createImage: function() {
            var html = '<div class="image"><div class="form-group"><label for="image[title][]">Title</label><input class="form-control" name="image[title][]" type="text" id="image[title][]"></div><div class="form-group"><label for="image[description][]">Description</label><input class="form-control" name="image[description][]" type="text" id="image[description][]"></div><div class="form-group"><label for="image[image][]">Image</label><input name="image[image][]" type="file" id="image[image][]"></div><div class="form-group"><label for="image[order][]">Order</label><input class="form-control" name="image[order][]" type="text" id="image[order][]"><div class="btn btn-danger btn-image-destroy">Remove Image</div></div></div>';
            this.project_images.append(html);
        },
        destroyImage: function(e) {
            var image_id = $(e.currentTarget).closest('.image').find('input[name="image[id][]"]').val();

            $.ajax({
                type: 'GET',
                url: paths.base + '/dashboard/images/destroy',
                data: { id: image_id },
                success: function(data) {
                    console.log(data);
                },
                error: function(data) {
                    console.log(data);
                }
            });

            $(e.currentTarget).closest('.image').remove();
        }
    };
    ProjectImages.init();


    /**
     * BoxImages
     * Updates images to fit within box
     * @type {{init: Function, updateImages: Function}}
     */
    var BoxImages = {
        init: function() {
            this.images = $('.box-image.box-square');
            this.fills = $('.box-image.box-image-fill');
            this.updateImages();
        },
        updateImages: function() {
            for (var i = 0; i < this.images.length; i++) {
                $(this.images[i]).find('img').hide();
                $(this.images[i]).css('padding-top', '100%');
                $(this.images[i]).css('background-image', 'url(' + $(this.images[i]).find('img').attr('src') + ')');
            }
            for (var i = 0; i < this.fills.length; i++) {
                $(this.fills[i]).find('img').hide();
                $(this.fills[i]).css('background-image', 'url(' + $(this.fills[i]).find('img').attr('src') + ')');
            }
        }
    };
    BoxImages.init();


    /**
     * BoxScale
     * Scales boxes to fit on screen properly in project view
     * @type {{init: Function, updateBoxes: Function}}
     */
    var BoxScale = {
        init: function() {
            this.boxes = $('.box.box-height-fix');
            this.updateBoxes();
        },
        updateBoxes: function() {
            for (var i = 0; i < this.boxes.length; i++) {
                $(this.boxes[i]).css('height', $('.box')[0].getBoundingClientRect().height);
            }
        }
    };
    BoxScale.init();

    $('img').on('load', function() {
        BoxScale.init();
    });

    $( window ).resize(function() {
        BoxScale.init();
    });


    /**
     * ProjectFilters
     * Show and hide projects based on the dropdown filter
     * @type {{init: Function, bindEvents: Function, filter: Function}}
     */
    var ProjectFilters = {
        init: function() {
            this.boxes = $('#projects .box');
            this.buttons = $('.project-filters a');
            this.bindEvents();
        },
        bindEvents: function() {
            this.buttons.click($.proxy(this.filter, this));
        },
        filter: function(e) {
            $('.project-filters a').removeClass('active');
            $(e.currentTarget).addClass('active');

            var filter = $(e.currentTarget).data('filter');
            this.boxes.hide();
            this.boxes.parent().find('.' + filter).fadeIn();
        }
    };
    ProjectFilters.init();

});